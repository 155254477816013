import React from 'react';
import { Navigate } from 'react-router-dom';

type AuthProps = {
  children: React.ReactNode;
};

export default function Auth({ children }: AuthProps) {
  // 仅判断是否有 token, 有则前端暂定认为登录
  const authed = localStorage.getItem('token');

  return authed ? <>{children}</> : <Navigate to="/" />;
}
