import React, { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Spin, Statistic, Tabs, Typography } from 'antd';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import {
  LoginByAccountParams,
  LoginBySMSCodeParams,
  loginByAccount,
  loginBySMSCode,
  sendSMSCode,
} from '../../api/auth';
import { LockOutlined, PhoneOutlined, SafetyOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

type LoginModalProps = {
  open: boolean;
  closeModal: () => void;
};

export default function LoginModal({ open, closeModal }: LoginModalProps) {
  const [rememberUsername, setRememberUsername] = useState<boolean>(true);
  const [loginTabKey, setLoginTabKey] = useState('1');
  const navigate = useNavigate();
  const [smsSending, setSmsSending] = useState<boolean>(false);
  const [allowResendSMS, setAllowResendSMS] = useState<boolean>(true);
  const [logining, setLogining] = useState<boolean>(false);
  const [modal, contextHolder] = Modal.useModal();
  const [smsForm] = Form.useForm();
  const phone = Form.useWatch('phone', smsForm);

  return (
    <>
      <Modal
        className={styles.loginModal}
        title={<Typography.Title level={4}>欢迎登录</Typography.Title>}
        open={open}
        footer={null}
        onCancel={() => {
          closeModal();
        }}
      >
        <Spin spinning={logining} tip="登录中">
          <Tabs
            activeKey={loginTabKey}
            onChange={key => {
              setLoginTabKey(key);
            }}
            items={[
              {
                key: '1',
                label: '账号密码登录',
                children: (
                  <Form
                    initialValues={{
                      username: localStorage.getItem('username') ? localStorage.getItem('username') : '',
                    }}
                    onFinish={(v: LoginByAccountParams) => {
                      if (rememberUsername) {
                        localStorage.setItem('username', v.username);
                      } else {
                        localStorage.removeItem('username');
                      }
                      // 登录
                      setLogining(true);
                      loginByAccount(v)
                        .then(res => {
                          console.log('login by account res', res);
                          if (res.data) {
                            localStorage.setItem('token', res.data);
                            navigate('/console');
                          }
                        })
                        .finally(() => {
                          setLogining(false);
                        });
                    }}
                  >
                    <Form.Item
                      name="username"
                      rules={[
                        { required: true, message: '请输入账号' },
                        { whitespace: true, message: '账号不能为空字符' },
                      ]}
                    >
                      <Input prefix={<UserOutlined />} placeholder="账号" />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: '请输入密码' },
                        { whitespace: true, message: '密码不能为空字符' },
                      ]}
                    >
                      <Input.Password prefix={<LockOutlined />} placeholder="密码" />
                    </Form.Item>
                    <Row justify="space-between" style={{ marginBottom: 24 }}>
                      <Col>
                        <Checkbox
                          checked={rememberUsername}
                          onChange={e => {
                            setRememberUsername(e.target.checked);
                          }}
                        >
                          记住账号
                        </Checkbox>
                      </Col>
                      <Col>
                        <Typography.Link>忘记密码</Typography.Link>
                      </Col>
                    </Row>
                    <Form.Item>
                      <Button disabled={logining} type="primary" htmlType="submit" style={{ width: '100%' }}>
                        登录
                      </Button>
                    </Form.Item>
                  </Form>
                ),
              },
              {
                key: '2',
                label: '手机号验证码登录',
                children: (
                  <Form
                    form={smsForm}
                    onFinish={(v: LoginBySMSCodeParams) => {
                      setLogining(true);
                      loginBySMSCode(v)
                        .then(res => {
                          console.log('login by sms res', res);
                          if (res.data) {
                            localStorage.setItem('token', res.data);
                            navigate('/console');
                          }
                        })
                        .finally(() => {
                          setLogining(false);
                        });
                    }}
                  >
                    <Form.Item
                      name="phone"
                      rules={[
                        { required: true, message: '请输入手机号' },
                        { pattern: /^(?:1\d{10}|0\d{2,3}-?\d{7,8})$/, message: '请输入正确的手机号' },
                        { whitespace: true, message: '手机号不能为空字符' },
                      ]}
                    >
                      <Input prefix={<PhoneOutlined />} placeholder="手机号" />
                    </Form.Item>
                    <Form.Item
                      name="smsCode"
                      rules={[
                        { required: true, message: '请输入验证码' },
                        { whitespace: true, message: '验证码不能为空字符' },
                      ]}
                    >
                      <Input
                        prefix={<SafetyOutlined />}
                        placeholder="验证码"
                        suffix={
                          <Typography.Link
                            disabled={smsSending || !allowResendSMS || !phone}
                            onClick={() => {
                              setSmsSending(true);
                              sendSMSCode(smsForm.getFieldValue('phone'))
                                .then(res => {
                                  if (res) {
                                    // 一分钟内禁止重发
                                    setAllowResendSMS(false);
                                    modal.success({
                                      title: '验证码发送成功',
                                    });
                                  }
                                })
                                .finally(() => {
                                  setSmsSending(false);
                                });
                            }}
                          >
                            {smsSending ? (
                              '发送中...'
                            ) : allowResendSMS ? (
                              '获取验证码'
                            ) : (
                              <>
                                <Statistic.Countdown
                                  style={{ display: 'inline-block' }}
                                  valueStyle={{ fontSize: '14px', color: 'var(--disabled-color)' }}
                                  format="ss"
                                  value={dayjs().add(1, 'minute').valueOf()}
                                  onFinish={() => {
                                    setAllowResendSMS(true);
                                  }}
                                />
                                秒后可重发
                              </>
                            )}
                          </Typography.Link>
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                        登录
                      </Button>
                    </Form.Item>
                  </Form>
                ),
              },
            ]}
          />
        </Spin>
      </Modal>
      {contextHolder}
    </>
  );
}
