import React, { useEffect, useState } from 'react';
import { Form, Col, Input, Tabs, List } from 'antd';
import { Filter, Paging, usePaging } from '@maxtropy/components';
import { Application, ApplicationListQuery, getApplicationList } from '../../api/application';
import ApplicationListItem from './ApplicationListItem';
import ConsoleContentWrapper from '../../components/ConsoleContentWrapper';

type SearchParams = Pick<ApplicationListQuery, 'name' | 'status'>;

// 应用管理列表页
export default function ApplicationManagement() {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [form] = Form.useForm();
  // 默认查询启用状态的应用
  const [searchParams, setSearchParams] = useState<SearchParams>({ status: '1' });
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState<Application[]>([]);

  useEffect(() => {
    setLoading(true);
    getApplicationList({ ...searchParams, count: pageSize, offset: pageOffset })
      .then(res => {
        if (res) {
          setApplications(res.data.records);
          setTotalCount(res.data.total);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageOffset, pageSize, searchParams, setTotalCount]);

  return (
    <ConsoleContentWrapper
      breadCrumbItems={[
        {
          title: '应用',
        },
        {
          title: '应用管理',
        },
      ]}
      filter={
        <Filter
          form={form}
          onFinish={(values: Pick<SearchParams, 'name'>) => {
            setPageOffset(1);
            setSearchParams({
              ...searchParams,
              name: values.name === '' ? undefined : values.name,
            });
          }}
          onReset={() => {
            setPageOffset(1);
            setSearchParams({
              ...searchParams,
              name: undefined,
            });
          }}
        >
          <>
            <Col span={6}>
              <Form.Item name="name" label="应用名称">
                <Input placeholder="请输入应用名称" />
              </Form.Item>
            </Col>
          </>
        </Filter>
      }
    >
      <Tabs
        defaultActiveKey="1"
        onChange={key => {
          setSearchParams({
            ...searchParams,
            status: key as '0' | '1',
          });
        }}
        items={[
          {
            key: '1',
            label: '启用',
            children: (
              <List
                footer={<div />}
                loading={loading}
                dataSource={applications}
                renderItem={item => {
                  return <ApplicationListItem item={item} />;
                }}
              />
            ),
          },
          {
            key: '0',
            label: '禁用',
            children: (
              <List
                footer={<div />}
                loading={loading}
                dataSource={applications}
                renderItem={item => {
                  return <ApplicationListItem disabled={true} item={item} />;
                }}
              />
            ),
          },
        ]}
      />
      <Paging pagingInfo={pagingInfo} />
    </ConsoleContentWrapper>
  );
}
