import axios, { AxiosError } from 'axios';
import { triggerModal } from '@maxtropy/components/es/components/CutomApp/hooks';
import router from '../router';
import { isNil } from 'lodash-es';

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (localStorage.getItem('token')) {
      config.headers['token'] = localStorage.getItem('token');
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  // prettier-ignore
  function (error: AxiosError) {// NOSONAR

    console.log('axios error', error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status! === 401) {
      // 重定向到首页并清除 token
      router.navigate('/');
      localStorage.removeItem('token');
    } else {
      if (isNil(error) || isNil(error.response) || isNil(error.response.status)) return;
      if (error.response.status >= 400) {
        let errorMessage;
        if (
          (error?.response?.data as any)?.errorType === 'invalid_parameter' &&
          (error?.response?.data as any)?.errorMessage === 'client getLogs error'
        ) {
          errorMessage = '获取日志错误！';
        } else {
          // 默认处理
          try {
            if (typeof error?.response?.data === 'string') {
              errorMessage =
                JSON.parse((error?.response?.data as any) || 'null').errorMessage || '未知错误！ 请联系管理员。';
            } else {
              errorMessage = (error?.response?.data as any)?.errorMessage || '未知错误！ 请联系管理员。';
            }
          } catch (e) {
            errorMessage = '未知错误！ 请联系管理员。';
          }
        }
        triggerModal({
          type: 'error',
          props: {
            content: errorMessage,
          },
        });
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
