import { useState } from 'react';
import { Button, Modal } from 'antd';
import { downloadMiniApp } from '../../api/scaffold';
import { getRealUrl } from '@maxtropy/components';

export default function MiniAppScaffold() {
  const [modal, contextHolder] = Modal.useModal();
  const [generatingScaffold, setGeneratingScaffold] = useState<boolean>(false);

  const download = () => {
    setGeneratingScaffold(true);
    downloadMiniApp()
      .then(res => {
        modal.success({
          content: '下载成功',
        });
        window.open(getRealUrl(res.data.data.value), '__blank');
      })
      .finally(() => {
        setGeneratingScaffold(false);
      });
  };

  return (
    <div style={{ textAlign: 'center', marginTop: 188 }}>
      <Button type="primary" onClick={download} disabled={generatingScaffold} loading={generatingScaffold}>
        {generatingScaffold ? '下载中...' : '下载框架'}
      </Button>
      {contextHolder}
    </div>
  );
}
