import React, { useEffect, useState } from 'react';
import { Layout, Typography, theme, Row, Col, Dropdown, Space, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getAccountInfo, logout } from '../../api/auth';
import LoginModal from './LoginModal';
import styles from './index.module.scss';

export default function Home() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const authed = localStorage.getItem('token') ? true : false;
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('获取用户信息中...');
  const [logouting, setLogouting] = useState<boolean>(false);

  // 获取已登录的用户信息
  useEffect(() => {
    if (authed) {
      getAccountInfo().then(res => {
        setUsername(res.data?.username);
      });
    }
  }, [authed]);

  return (
    <Layout className="layout" style={{ height: '100vh' }}>
      <Layout.Header style={{ background: colorBgContainer }}>
        <Row gutter={32} justify="space-around">
          <Col>
            <Space size="large" align="center">
              <img style={{ display: 'inline-block', verticalAlign: 'middle' }} src="/logo-light.svg" alt="logo" />
              <Typography.Text>极熵开放平台</Typography.Text>
              <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    {
                      key: 1,
                      label: (
                        <Typography.Text
                          onClick={() => {
                            navigate('/api-docs');
                          }}
                        >
                          接口文档
                        </Typography.Text>
                      ),
                    },
                    {
                      key: 2,
                      label: (
                        <Typography.Text
                          onClick={() => {
                            window.open(
                              'http://open-platform-guide.maxtropy.cloud/open-platform-guide/index.html#/',
                              '_blank'
                            );
                          }}
                        >
                          开发指南
                        </Typography.Text>
                      ),
                    },
                  ],
                }}
              >
                <Typography.Text style={{ cursor: 'pointer' }}>
                  <Space>
                    文档
                    <DownOutlined />
                  </Space>
                </Typography.Text>
              </Dropdown>

              {/* <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    {
                      key: 1,
                      label: '技术支持：0510-012233',
                    },
                  ],
                }}
              >
                <Typography.Text style={{ cursor: 'pointer' }}>
                  <Space>
                    帮助支持
                    <DownOutlined />
                  </Space>
                </Typography.Text>
              </Dropdown> */}
            </Space>
          </Col>

          <Col>
            {authed ? (
              <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    {
                      key: 1,
                      label: (
                        <Typography.Text
                          disabled={logouting}
                          onClick={() => {
                            navigate('/console');
                          }}
                        >
                          控制台
                        </Typography.Text>
                      ),
                    },
                    {
                      key: 2,
                      label: (
                        <Typography.Text
                          disabled={logouting}
                          onClick={() => {
                            setLogouting(true);
                            logout().then(res => {
                              console.log('logout res', res);
                              if (res.data) {
                                setLogouting(false);
                                localStorage.removeItem('token');
                              }
                            });
                          }}
                        >
                          退出
                        </Typography.Text>
                      ),
                    },
                  ],
                }}
              >
                <Typography.Text style={{ cursor: 'pointer' }}>
                  <Space>
                    {logouting ? '登出中...' : username}
                    <DownOutlined />
                  </Space>
                </Typography.Text>
              </Dropdown>
            ) : (
              <Button
                type="primary"
                shape="round"
                size="small"
                onClick={() => {
                  setShowLoginModal(true);
                }}
              >
                登录
              </Button>
            )}
          </Col>
        </Row>
      </Layout.Header>
      <Layout.Content
        style={{
          height: '100%',
          width: '100%',
          backgroundSize: 'cover',
          backgroundImage: 'url(./banner.jpg)',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div style={{ marginLeft: '20vw', marginTop: '20vh' }}>
          <Typography.Text
            strong
            style={{ display: 'block' }}
            className={`${styles.responsiveLargeFont} ${styles.responsiveMiddleFont}`}
          >
            熵云开放平台
          </Typography.Text>
          <Typography.Text>性能强大、安全、稳定的云产品与服务</Typography.Text>
        </div>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center', background: colorBgContainer }}>
        © Copyright 2023 极熵数据
      </Layout.Footer>

      {showLoginModal && (
        <LoginModal
          open={showLoginModal}
          closeModal={() => {
            setShowLoginModal(false);
          }}
        />
      )}
    </Layout>
  );
}
