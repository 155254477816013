import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import { ThemeProvider, Themes } from '@maxtropy/components';

dayjs.locale('zh-cn');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ThemeProvider theme={Themes.SAPPHIRE}>
    <App />
  </ThemeProvider>
);
