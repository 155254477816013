import { useEffect, useState } from 'react';
import { getRealUrl } from '@maxtropy/components';
import axios from '../axios';
import { isNil } from 'lodash-es';

export const useImage = (originUrl: string | undefined) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(originUrl);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);

  // 带入 token 获取图片并创建 objectUrl 返回
  useEffect(() => {
    if (originUrl) {
      const logUrl = getRealUrl(originUrl);
      if (!isNil(logUrl)) {
        setLoadingImage(true);
        axios
          .get(logUrl, {
            responseType: 'blob',
          })
          .then(res => {
            const objectUrl = URL.createObjectURL(res.data);
            setImageUrl(objectUrl);
          })
          .finally(() => {
            setLoadingImage(false);
          });
      }
    }
  }, [originUrl]);

  return {
    imageUrl,
    setImageUrl,
    loadingImage,
  };
};
