import React, { useEffect, useState } from 'react';
import { Col, Divider, List, Row, Space, Statistic, theme, Typography } from 'antd';
import styles from './index.module.scss';
import { FileDoneOutlined, FileSearchOutlined, FileTextOutlined, TagsOutlined } from '@ant-design/icons';
import { getHomepageInfo, HomepageInfo } from '../../api/console';
import { useNavigate } from 'react-router-dom';

// 后台首页
export default function Console() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [info, setInfo] = useState<HomepageInfo>();
  const navigate = useNavigate();

  useEffect(() => {
    getHomepageInfo().then(res => {
      setInfo(res.data);
    });
  }, []);

  return (
    <>
      <div style={{ marginTop: 4, padding: '24px 32px', background: colorBgContainer }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Space direction="vertical">
              <Typography.Title level={3}>{info?.companyName}</Typography.Title>
              {/* <Typography.Text type="secondary">这里是一些简介</Typography.Text> */}
            </Space>
          </Col>
          <Col>
            <Space split={<Divider type="vertical" style={{ height: '2.5rem' }} />}>
              <Statistic title="应用数" value={info?.applications?.length ?? 0} />
              <Statistic title="账号" value={info?.developerAccounts?.length ?? 0} />
            </Space>
          </Col>
        </Row>
      </div>
      <Row gutter={24} style={{ margin: '24px 12px', minHeight: '75vh' }}>
        <Col span={16}>
          <div style={{ background: colorBgContainer, height: '100%' }}>
            <div>
              <Typography.Title level={5} style={{ margin: 0, padding: '24px 24px 0 24px' }}>
                发布动态
              </Typography.Title>
              <Divider style={{ margin: '12px 0' }} />
            </div>
            <div style={{ padding: '0 24px 0 24px' }}>
              <List
                dataSource={[]}
                renderItem={item => {
                  return (
                    <List.Item>
                      <Typography.Text>{item}</Typography.Text>
                    </List.Item>
                  );
                }}
              />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div style={{ background: colorBgContainer, height: '100%' }}>
            <Typography.Title level={5} style={{ margin: 0, padding: '24px 24px 0 24px' }}>
              快速开始 / 便捷导航
            </Typography.Title>
            <Divider style={{ margin: '12px 0' }} />
            <Row style={{ padding: '16px 0' }} justify="space-around">
              <Col>
                <div className={styles.quickNav}>
                  <Space
                    onClick={() => {
                      window.open(
                        'http://open-platform-guide.maxtropy.cloud/open-platform-guide/index.html#/',
                        '_blank'
                      );
                    }}
                  >
                    <FileDoneOutlined />
                    开发指南
                  </Space>
                </div>
              </Col>
              <Col>
                <div className={styles.quickNav}>
                  <Space
                    onClick={() => {
                      navigate('/api-docs');
                    }}
                  >
                    <FileTextOutlined />
                    接口文档
                  </Space>
                </div>
              </Col>
            </Row>
            <Row style={{ padding: '16px 0' }} justify="space-around">
              <Col>
                <div className={styles.quickNav}>
                  <Space
                    onClick={() => {
                      navigate('/console/tools/logs');
                    }}
                  >
                    <FileSearchOutlined />
                    日志查询
                  </Space>
                </div>
              </Col>
              <Col>
                <div className={styles.quickNav} onClick={() => {}}>
                  <Space
                    onClick={() => {
                      navigate('/console/release/application');
                    }}
                  >
                    <TagsOutlined />
                    发布应用
                  </Space>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}
