import React from 'react';
import './App.scss';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { useThemeContext } from '@maxtropy/components';
import zhCN from 'antd/es/locale/zh_CN';
import CustomApp from '@maxtropy/components/es/components/CutomApp';
import router from './router';

function App() {
  const theme = useThemeContext();

  return (
    <ConfigProvider locale={zhCN} theme={theme}>
      <CustomApp>
        <RouterProvider router={router} />
      </CustomApp>
    </ConfigProvider>
  );
}

export default App;
