import axios from '../axios';
import { V2PageData } from './page';
import { V2Response } from './utils';

export interface LogsQuery {
  // 服务名称
  serviceName?: string;
  // 表达式
  expression?: string;
  // 开始时间，秒级时间戳
  fromTime?: string;
  // 结束时间，秒级时间戳
  toTime?: string;
  // false 正序, true 倒序
  reverse?: boolean;
  // 页码, 第几页
  page: number;
  // 分页器一页条数, 例如每页展示 10 条
  size: number;
}

export interface Log {
  // 日志内容一般为 JSON 格式
  '@timestamp': string;
  class: string;
  level: string;
  logger: string;
  message: string;
  service_name: string;
  stack_trace: string;
  thread: string;
  trace_id: string;
}

// 获取日志列表
export function getLogs(params: LogsQuery) {
  return axios.post<V2Response<V2PageData<Log>>>(`/api/v2/log/page`, {
    ...params,
    timestamp: new Date().valueOf(),
  });
}

export interface PlatformService {
  id?: number;
  name?: string;
  serviceName?: string;
  applicationId?: number;
  independentSoftwareVendorId?: number;
}

export function getPlatformServiceList() {
  return axios.get<PlatformService[]>('/api/platform-service/list');
}
