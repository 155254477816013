import axios from '../axios';

export type LoginByAccountParams = {
  username: string;
  password: string;
};

// 用户名密码登录, 返回 token
export function loginByAccount(params: LoginByAccountParams) {
  return axios.post<string>('/api/account/login', params, {
    responseType: 'text',
  });
}

export type LoginBySMSCodeParams = {
  // 手机号
  phone: string;
  // 短信
  smsCode: string;
};

// 手机账号登录
export function loginBySMSCode(params: LoginBySMSCodeParams) {
  return axios.post<string>('/api/account/loginBySmsCode', params, {
    responseType: 'text',
  });
}

// 发送短信验证码
export function sendSMSCode(phone: string) {
  return axios.get<boolean>(`/api/account/sendSmsCode?phone=${phone}`);
}

// 登出
export function logout() {
  return axios.get<boolean>('/api/account/logout');
}

export interface AccountInfo {
  id: number;
  // 服务商id
  independentSoftwareVendorId: number;
  // 服务商名称
  independentSoftwareVendorName: string;
  // 服务商状态
  independentSoftwareVendorStatus: number;
  // 角色类型, 1 :管理员 2 :普通
  roleType: 1 | 2;
  // 账号
  username: string;
  // 密码
  password: string;
  // 状态, 0 :禁用 1 :启用
  status: number;
  // 手机号
  cellphone: string;
  // 备注
  description: string;
  // 创建时间
  createTime: string;
  // 更新时间
  updateTime: string;
}

// 登录后账号信息
export function getAccountInfo() {
  return axios.get<AccountInfo>('/api/account/info');
}
