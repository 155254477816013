import axios from '../axios';
import { Application } from './application';
import { AccountInfo } from './auth';

export interface HomepageInfo {
  id: number;
  applications?: Application[];
  companyName?: string;
  createTime?: string;
  updateTime?: string;
  developerAccounts?: AccountInfo[];
  introduction?: string;
  logoResource?: string;
  status?: number;
}

export function getHomepageInfo() {
  return axios.get<HomepageInfo>('/api/isv/self');
}
