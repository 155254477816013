import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';
import Layout from '../components/ConsoleLayout';
import Home from '../pages/Home';
import { Empty } from 'antd';
import Console from '../pages/Console';
import ApplicationManagement from '../pages/Application';
import ApplicationDetail from '../pages/Application/Detail';
import Auth from '../components/Auth';
import ApplicationApiAuthority from '../pages/Application/ApiAuthority';
import RootRouteBoundary from '../components/RootRouteBoundary';
import APIDocs from '../pages/APIDocs';
import Scaffold from '../pages/Tools/Scaffold';
import LogList from '../pages/Tools/LogList';
import Release from '../pages/Release';

const routes: RouteObject[] = [
  // 首页
  {
    path: '/',
    element: <Home />,
    errorElement: <RootRouteBoundary />,
  },
  {
    path: '/api-docs',
    element: <APIDocs />,
  },
  {
    path: '/console',
    element: (
      <Auth>
        <Layout>
          <Outlet />
        </Layout>
      </Auth>
    ),
    children: [
      {
        index: true,
        element: <Console />,
      },
      {
        path: 'application',
        element: <Outlet />,
        children: [
          {
            path: 'management',
            element: <ApplicationManagement />,
          },
          {
            path: 'management/detail/:id',
            element: <ApplicationDetail />,
          },
          {
            path: 'management/api-authority/:id',
            element: <ApplicationApiAuthority />,
          },
          {
            path: 'test',
            element: <div />,
          },
        ],
      },
      {
        path: 'release',
        element: <Outlet />,
        children: [
          {
            path: 'application',
            element: <Release />,
          },
        ],
      },
      {
        path: 'tools',
        element: <Outlet />,
        children: [
          {
            path: 'scaffold',
            element: <Scaffold />,
          },
          {
            path: 'logs',
            element: <LogList />,
          },
        ],
      },
      {
        path: 'account',
        element: <div />,
      },
      {
        path: '*',
        element: <Empty description="404" />,
      },
    ],
  },
  {
    path: '*',
    element: <Empty description="404" />,
  },
];

const router = createBrowserRouter(routes);

export default router;
