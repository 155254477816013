import ConsoleContentWrapper from '../../components/ConsoleContentWrapper';
import { Tabs } from 'antd';
import BackEndScaffold from './BackendScaffold';
import FrontEndScaffold from './FrontScaffold';
import MiniAppScaffold from './MiniAppScaffold';
import styles from './index.module.scss';

export default function Scaffold() {
  return (
    <ConsoleContentWrapper
      contentStyle={{ minHeight: '80vh' }}
      breadCrumbItems={[
        {
          title: '应用',
        },
        {
          title: '工具',
        },
        {
          title: '脚手架',
        },
      ]}
    >
      <Tabs
        defaultActiveKey="1"
        type="card"
        className={styles.scaffoldTab}
        items={[
          {
            key: '1',
            label: '后端',
            children: <BackEndScaffold />,
          },
          {
            key: '2',
            label: '前端',
            children: <FrontEndScaffold />,
          },
          {
            key: '3',
            label: '小程序',
            children: <MiniAppScaffold />,
          },
        ]}
      />
    </ConsoleContentWrapper>
  );
}
