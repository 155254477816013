import React, { useState } from 'react';
import { theme, Layout, Tabs, Row, Col, Space, Typography, Dropdown, Button } from 'antd';
import 'rapidoc';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'rapi-doc': any;
    }
  }
}

// 应用管理列表页
export default function APIDocs() {
  const {
    token: { colorBgContainer, colorPrimary, colorPrimaryHover, colorBgBase },
  } = theme.useToken();
  const [apiTabKey, setApiTabKey] = useState('base');
  const navigate = useNavigate();

  return (
    <Layout>
      <Layout.Header style={{ background: colorBgContainer }}>
        <Row gutter={32} justify="space-around">
          <Col>
            <Space size="large" align="center">
              <img style={{ display: 'inline-block', verticalAlign: 'middle' }} src="/logo-light.svg" alt="logo" />
              <Typography.Text>极熵开放平台</Typography.Text>
              <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    {
                      key: 1,
                      label: (
                        <Typography.Text
                          onClick={() => {
                            navigate('/api-docs');
                          }}
                        >
                          接口文档
                        </Typography.Text>
                      ),
                    },
                    {
                      key: 2,
                      label: (
                        <Typography.Text
                          onClick={() => {
                            window.open(
                              'http://open-platform-guide.maxtropy.cloud/open-platform-guide/index.html#/',
                              '_blank'
                            );
                          }}
                        >
                          开发指南
                        </Typography.Text>
                      ),
                    },
                  ],
                }}
              >
                <Typography.Text style={{ cursor: 'pointer' }}>
                  <Space>
                    文档
                    <DownOutlined />
                  </Space>
                </Typography.Text>
              </Dropdown>

              {/* <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    {
                      key: 1,
                      label: '技术支持：0510-012233',
                    },
                  ],
                }}
              >
                <Typography.Text style={{ cursor: 'pointer' }}>
                  <Space>
                    帮助支持
                    <DownOutlined />
                  </Space>
                </Typography.Text>
              </Dropdown> */}
            </Space>
          </Col>

          <Col>
            <Button
              size="small"
              shape="round"
              type="primary"
              onClick={() => {
                navigate('/');
              }}
            >
              回到首页
            </Button>
          </Col>
        </Row>
      </Layout.Header>
      <Layout>
        <Layout.Content style={{ margin: 24, padding: 24, background: colorBgContainer, minHeight: '80vh' }}>
          <Tabs
            // type="card"
            activeKey={apiTabKey}
            onChange={key => {
              setApiTabKey(key);
            }}
            // tabPosition="left"
            items={['base', 'device', 'auth', 'dmes'].map(v => ({
              label: v,
              key: v,
              forceRender: true,
              children: (
                <rapi-doc
                  class="rapi-docs"
                  spec-url={`${window.SELECTOPENORIGIN}/api/upstreamService/${v}/openApi`}
                  // 禁止 hash 路由的改变
                  update-route="false"
                  // render-style="focused"
                  // 禁止加载本地和远程 json
                  allow-spec-url-load="false"
                  allow-spec-file-load="false"
                  // allow-spec-file-download="true"
                  // show-components="true"
                  // show-curl-before-try="true"
                  allow-try="false"
                  show-header="false"
                  // 主题
                  theme="light"
                  primary-color={colorPrimary}
                  nav-bg-color={colorBgBase}
                  nav-hover-text-color={colorPrimaryHover}
                  bg-colo={colorBgContainer}
                  // schema
                  schema-style="table"
                  schema-description-expanded="true"
                  show-method-in-nav-bar="as-colored-text"
                  server-url={`https://apigw.test.maxtropy.com/${v}`}
                />
              ),
            }))}
          />
        </Layout.Content>
      </Layout>
      <Layout.Footer style={{ textAlign: 'center', background: colorBgContainer }}>
        © Copyright 2023 极熵数据
      </Layout.Footer>
    </Layout>
  );
}
