import React, { useEffect, useState } from 'react';
import { Layout, theme, Menu, Space, Typography, Row, Col, Dropdown } from 'antd';
import {
  AppstoreOutlined,
  DashboardOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TagOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import styles from './index.module.scss';
import { getAccountInfo, logout } from '../api/auth';

type LayoutProps = {
  children: React.ReactNode;
};

type MenuItem = Required<MenuProps>['items'][number];

// 后台布局
export default function ConsoleLayout(props: LayoutProps) {
  // 配置左侧菜单栏, 结合路由
  const items: MenuItem[] = [
    {
      key: '/console',
      label: '首页',
      icon: <DashboardOutlined />,
    },
    {
      key: '/console/application',
      label: '应用',
      icon: <AppstoreOutlined />,
      children: [{ key: '/console/application/management', label: '应用管理' }],
    },
    {
      key: '/console/release',
      label: '发布',
      icon: <TagOutlined />,
      children: [
        {
          key: '/console/release/application',
          label: '发布应用',
        },
      ],
    },
    {
      key: '/console/tools',
      label: '工具',
      icon: <ToolOutlined />,
      children: [
        {
          key: '/console/tools/scaffold',
          label: '脚手架',
        },
        {
          key: '/console/tools/logs',
          label: '日志查询',
        },
      ],
    },
    // { key: '/console/account', label: '账号', icon: <UserOutlined />, children: [] },
  ];
  const { children } = props;
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const authed = localStorage.getItem('token') ? true : false;
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const [username, setUsername] = useState<string>('获取用户信息中...');
  const [logouting, setLogouting] = useState<boolean>(false);
  const [openKeys, setOpenKeys] = useState<string[]>(() => {
    // 一级所有含有 children 的 key, matchItem 会进行子路由的匹配
    // 例如 matchPath('/a/*', '/a/b') 这种满足匹配会返回一个对象, 否则 matchPath('/c/*', '/a/b') 这种不满足匹配返回 null
    const matchedItem = items
      .filter((v: any) => v.children)
      .find(item => matchPath(`${item?.key!}/*`, location.pathname));
    return matchedItem ? [matchedItem.key as string] : [];
  });
  const [selectedKeys, setSelectedKeys] = useState<string[]>(() => {
    // 如果第一级匹配, 直接选中第一级
    const firstLevelMatchedItem = items
      .filter((v: any) => !v.children)
      .find(item => matchPath(`${item?.key!}`, location.pathname));
    if (firstLevelMatchedItem) {
      return [firstLevelMatchedItem.key as string];
    }
    // 否则匹配项在第二级
    const matchedItem = items
      .filter((v: any) => v.children)
      .reduce<MenuItem[]>((result, v: any) => {
        if (v.children) {
          result.push(...v.children);
        } else {
          result.push(v);
        }
        return result;
      }, [])
      .find(item => matchPath(`${item?.key!}/*`, location.pathname));
    return matchedItem ? [matchedItem.key as string] : [];
  });

  useEffect(() => {
    if (authed) {
      getAccountInfo().then(res => {
        setUsername(res.data?.username);
      });
    }
  }, [authed]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Sider
        trigger={null}
        collapsedWidth={0}
        collapsible
        collapsed={collapsed}
        onCollapse={value => {
          setCollapsed(value);
        }}
      >
        <div style={{ height: 32, margin: 16 }}>
          <Space
            style={{ cursor: 'pointer' }}
            size="small"
            onClick={() => {
              navigate('/');
            }}
          >
            <img src="/logo-dark.svg" alt="logo-dark" />
            <Typography.Text style={{ color: 'white' }}>熵云开放平台</Typography.Text>
          </Space>
        </div>
        <Menu
          className={styles.menu}
          theme="dark"
          mode="inline"
          items={items}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onSelect={selectInfo => {
            setSelectedKeys(selectInfo.selectedKeys);
          }}
          onClick={clickInfo => {
            navigate(clickInfo.key);
          }}
          onOpenChange={keys => {
            setOpenKeys(keys);
          }}
        />
      </Layout.Sider>
      <Layout>
        <Layout.Header style={{ padding: '0 24px', background: colorBgContainer }}>
          <Row justify="space-between">
            <Col>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </div>
            </Col>
            <Col>
              <Dropdown
                trigger={['click']}
                placement="bottom"
                menu={{
                  items: [
                    // {
                    //   key: 1,
                    //   label: <Typography.Text disabled={logouting}>账号信息</Typography.Text>,
                    // },
                    {
                      key: 2,
                      label: (
                        <Typography.Text
                          disabled={logouting}
                          onClick={() => {
                            setLogouting(true);
                            logout().then(res => {
                              if (res.data) {
                                localStorage.removeItem('token');
                                navigate('/');
                                setLogouting(false);
                              }
                            });
                          }}
                        >
                          退出
                        </Typography.Text>
                      ),
                    },
                  ],
                }}
              >
                <Typography.Text style={{ cursor: 'pointer' }}>
                  <Space>
                    {logouting ? '登出中...' : username}
                    <DownOutlined />
                  </Space>
                </Typography.Text>
              </Dropdown>
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content>{children}</Layout.Content>
      </Layout>
    </Layout>
  );
}
