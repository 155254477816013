import React, { useEffect, useState, useMemo } from 'react';
import ConsoleContentWrapper from '../../components/ConsoleContentWrapper';
import { Filter, Table, Paging, usePaging, EllipsisSpan } from '@maxtropy/components';
import { Col, DatePicker, Form, Input, Popover, Select, Space, theme } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { Log, LogsQuery, PlatformService, getLogs, getPlatformServiceList } from '../../api/log';
import styles from './index.module.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { SorterResult } from 'antd/es/table/interface';
import JSONPretty from 'react-json-pretty';

type SearchParams = Pick<LogsQuery, 'serviceName' | 'expression' | 'fromTime' | 'toTime'>;

type FormValues = Pick<LogsQuery, 'serviceName' | 'expression'> & { time: [Dayjs, Dayjs] };

type LogDataSource = {
  content: Log;
  time: string;
};

export default function LogList() {
  const {
    token: { colorPrimary, colorPrimaryBg },
  } = theme.useToken();
  // 应用下拉列表
  const [platformServiceList, setPlatformServiceList] = useState<PlatformService[]>([]);
  const initialValues: FormValues = useMemo(
    () => ({
      serviceName: platformServiceList[0]?.serviceName,
      time: [dayjs().subtract(5, 'minutes'), dayjs()],
    }),
    [platformServiceList]
  );
  const [form] = Form.useForm<FormValues>();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>({
    serviceName: initialValues.serviceName!,
    fromTime: initialValues?.time?.[0]?.unix().toString(),
    toTime: initialValues?.time?.[1]?.unix().toString(),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [platformServiceListLoading, setPlatformServiceListLoading] = useState<boolean>(false);
  const [logList, setLogList] = useState<LogDataSource[]>([]);
  // 日志是否正序(false 降序, 最近的时间在前, true 维倒序正序)
  const [logListReversed, setLogListReversed] = useState(false);
  // hover 的 preset
  const [hoveredPreset, setHoveredPreset] = useState<[Dayjs, Dayjs] | null>(null);

  const columns: ColumnsType<LogDataSource> = [
    {
      title: '日志内容',
      dataIndex: 'content',
      width: '90%',
      // ellipsis: { showTitle: true },
      render: (v: LogDataSource['content']) => {
        return <JSONPretty data={v} keyStyle={`background-color: ${colorPrimaryBg}`} />;
      },
    },
    {
      title: '时间',
      dataIndex: 'time',
      ellipsis: { showTitle: true },
      sorter: true,
      // 默认降序, 最新时间在前
      sortOrder: logListReversed ? 'ascend' : 'descend',
      render: (v: LogDataSource['time']) => {
        return <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />;
      },
    },
  ];

  const presets: { label: string; value: [Dayjs, Dayjs] }[] = [
    {
      label: '1分钟',
      value: [dayjs().subtract(1, 'minute'), dayjs()],
    },
    {
      label: '5分钟',
      value: [dayjs().subtract(5, 'minutes'), dayjs()],
    },
    {
      label: '15分钟',
      value: [dayjs().subtract(15, 'minutes'), dayjs()],
    },
    {
      label: '1小时',
      value: [dayjs().subtract(1, 'hour'), dayjs()],
    },
    {
      label: '4小时',
      value: [dayjs().subtract(4, 'hours'), dayjs()],
    },
    {
      label: '1天',
      value: [dayjs().subtract(1, 'day'), dayjs()],
    },
    {
      label: '今天',
      value: [dayjs().startOf('day'), dayjs()],
    },
    {
      label: '1周',
      value: [dayjs().subtract(1, 'week'), dayjs()],
    },
    {
      label: '本周',
      value: [dayjs().startOf('week'), dayjs()],
    },
    {
      label: '30天',
      value: [dayjs().subtract(30, 'days'), dayjs()],
    },
    {
      label: '本月',
      value: [dayjs().startOf('month'), dayjs()],
    },
  ];

  useEffect(() => {
    form.setFieldsValue(initialValues);
    form.submit();
  }, [form, initialValues]);

  // 获取应用平台服务
  useEffect(() => {
    setPlatformServiceListLoading(true);
    getPlatformServiceList()
      .then(res => {
        console.log('111', res);
        setPlatformServiceList(res.data || []);
      })
      .finally(() => {
        setPlatformServiceListLoading(false);
      });
  }, []);

  // 获取日志
  useEffect(() => {
    if (searchParams.serviceName) {
      setLoading(true);
      getLogs({ ...searchParams, reverse: logListReversed, size: pageSize, page: pageOffset })
        .then(res => {
          if (res) {
            setLogList(
              res.data.data.list.map(v => ({
                content: v,
                time: v['@timestamp'],
              }))
            );
            setTotalCount(res.data.data.total);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLogList([]);
    }
  }, [searchParams, pageOffset, pageSize, setTotalCount, logListReversed]);

  return (
    <ConsoleContentWrapper
      breadCrumbItems={[
        {
          title: '工具',
        },
        {
          title: '日志查询',
        },
      ]}
      filter={
        <Filter
          initialValues={initialValues}
          form={form}
          onFinish={(values: FormValues) => {
            setPageOffset(1);
            setSearchParams({
              serviceName: values.serviceName,
              expression: values.expression,
              fromTime: values.time?.[0]?.unix()?.toString(),
              toTime: values.time?.[1]?.unix()?.toString(),
            });
          }}
          onReset={() => {
            setPageOffset(1);
            setLogListReversed(false);
            setSearchParams({
              serviceName: initialValues.serviceName,
              fromTime: initialValues?.time?.[0]?.unix().toString(),
              toTime: initialValues?.time?.[1]?.unix().toString(),
            });
          }}
        >
          <>
            <Col span={6}>
              <Form.Item name="serviceName" label="应用">
                <Select
                  loading={platformServiceListLoading}
                  placeholder="请选择应用"
                  options={(platformServiceList ?? []).map(s => ({
                    label: s.serviceName,
                    value: s.serviceName,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Space align="baseline" style={{ width: '100%' }} className={styles.expression}>
                <Form.Item name="expression" label="表达式">
                  <Input placeholder="请输入表达式" />
                </Form.Item>
                <Popover
                  trigger="click"
                  content={
                    <>
                      <p>
                        <strong>查询：</strong>
                      </p>
                      <p>1. 全文查询：error</p>
                      <p>2. 指定字段查询：Latency{'>'}5000 and Method:Get* and not Status:200</p>
                      <p>3. 短语（指定引号）：#"http error"</p>
                      <p>
                        <strong>分析：</strong>
                      </p>
                      <p>1. 无查询条件：* | $analytics</p>
                      <p>2. 带查询条件：$search | $analytics</p>
                    </>
                  }
                >
                  <QuestionCircleOutlined style={{ color: colorPrimary, cursor: 'pointer' }} />
                </Popover>
              </Space>
            </Col>
            <Col span={8}>
              <Form.Item name="time" label="时间">
                <DatePicker.RangePicker
                  allowClear={false}
                  showTime
                  renderExtraFooter={() => (
                    // NOSONAR
                    <div style={{ padding: '0 24px' }}>
                      {hoveredPreset
                        ? `${dayjs(hoveredPreset?.[0]).format('YYYY-MM-DD HH:mm:ss')} ~ ${dayjs(
                            hoveredPreset?.[1]
                          ).format('YYYY-MM-DD HH:mm:ss')}`
                        : '可在左侧选择相对于当前时间的日期'}
                    </div>
                  )}
                  presets={presets.map(p => ({
                    label: (
                      <div
                        onMouseOver={() => {
                          setHoveredPreset(p.value);
                        }}
                        onMouseOut={() => {
                          setHoveredPreset(null);
                        }}
                      >
                        {p.label}
                      </div>
                    ),
                    value: p.value,
                  }))}
                />
              </Form.Item>
            </Col>
          </>
        </Filter>
      }
    >
      <Table
        columns={columns}
        dataSource={logList}
        loading={loading}
        sortDirections={['ascend', 'descend', 'ascend']}
        onChange={(pagination, filters, sorter, extra) => {
          if (extra.action === 'sort') {
            if ((sorter as SorterResult<Log>).order === 'ascend') {
              setLogListReversed(true);
            } else {
              setLogListReversed(false);
            }
          }
        }}
      />
      <Paging pagingInfo={pagingInfo} />
    </ConsoleContentWrapper>
  );
}
