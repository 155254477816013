import React from 'react';
import ConsoleContentWrapper from '../../components/ConsoleContentWrapper';
import { Empty } from 'antd';

export default function Release() {
  return (
    <ConsoleContentWrapper
      breadCrumbItems={[
        {
          title: '发布',
        },
        {
          title: '发布应用',
        },
      ]}
      contentStyle={{ minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Empty description="该功能正在建设中" />
    </ConsoleContentWrapper>
  );
}
