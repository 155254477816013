import React, { useState, useEffect, useMemo } from 'react';
import { Form, Col, Select, Input, Space, Button } from 'antd';
import { Filter, Table, EllipsisSpan } from '@maxtropy/components';
import { APIAuthority, getApplicationDetail } from '../../api/application';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { uniqBy } from 'lodash-es';
import ConsoleContentWrapper from '../../components/ConsoleContentWrapper';

type SearchParams = Partial<Pick<APIAuthority, 'serviceName' | 'requestUrl'>>;

// 接口权限列表
export default function ApplicationApiAuthority() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({} as SearchParams);
  const [apiAuthorities, setApiAuthorities] = useState<APIAuthority[]>([]);
  const filteredApiAuthorities = useMemo(() => {
    return apiAuthorities
      .filter(v => (searchParams.serviceName ? v.serviceName === searchParams.serviceName : true))
      .filter(v => v.requestUrl?.includes(searchParams?.requestUrl ?? ''));
  }, [apiAuthorities, searchParams]);

  const [loading, setLoading] = useState<boolean>(false);

  const columns: ColumnsType<APIAuthority> = [
    {
      title: '服务',
      dataIndex: 'serviceName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '调用方式',
      dataIndex: 'requestMethod',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '接口',
      dataIndex: 'requestUrl',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  // 获取应用详情
  useEffect(() => {
    setLoading(true);
    if (id) {
      getApplicationDetail(id)
        .then(res => {
          setApiAuthorities(res?.data?.apiAuthorities ?? []);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <ConsoleContentWrapper
      breadCrumbItems={[
        {
          title: '应用',
        },
        {
          title: '应用管理',
          path: '/console/application/management',
        },
        {
          title: '接口权限',
        },
      ]}
      filter={
        <Filter
          form={form}
          onFinish={(values: SearchParams) => {
            setSearchParams(values);
          }}
          onReset={() => {
            setSearchParams({} as SearchParams);
          }}
        >
          <>
            <Col span={6}>
              <Form.Item name="serviceName" label="服务">
                <Select
                  allowClear
                  placeholder="请选择服务"
                  options={uniqBy(apiAuthorities ?? [], 'serviceName')?.map(v => ({
                    label: v.serviceName,
                    value: v.serviceName,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="requestUrl" label="接口">
                <Input placeholder="请输入接口" />
              </Form.Item>
            </Col>
          </>
        </Filter>
      }
    >
      <Table rowKey="id" columns={columns} dataSource={filteredApiAuthorities} loading={loading} />
      <Space style={{ position: 'sticky', top: '100%', marginTop: 32 }}>
        <Button
          type="default"
          onClick={() => {
            navigate('/console/application/management');
          }}
        >
          返回列表
        </Button>
      </Space>
    </ConsoleContentWrapper>
  );
}
