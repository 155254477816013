import { useState } from 'react';
import { Form, Input, Button, Modal, Select } from 'antd';
import { generateFrontScaffold, GenerateFrontScaffoldParams } from '../../api/scaffold';
import { getRealUrl } from '@maxtropy/components';

export default function FrontEndScaffold() {
  const [modal, contextHolder] = Modal.useModal();
  const [generatingScaffold, setGeneratingScaffold] = useState<boolean>(false);

  return (
    <>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        labelAlign="right"
        onFinish={(values: GenerateFrontScaffoldParams) => {
          setGeneratingScaffold(true);
          generateFrontScaffold(values)
            .then(res => {
              modal.success({
                content: '生成脚手架成功',
              });
              window.open(getRealUrl(res.data.data.value), '__blank');
            })
            .finally(() => {
              setGeneratingScaffold(false);
            });
        }}
      >
        <Form.Item
          name="appName"
          label="应用名称"
          rules={[
            { required: true, message: '请输入应用名称' },
            { pattern: /^[a-zA-Z0-9_]*$/, message: '只能包含英文、数字、下划线' },
            { min: 5, max: 30, message: '5-30字之间' },
          ]}
        >
          <Input placeholder="请输入应用名称" />
        </Form.Item>
        <Form.Item
          name="version"
          label="版本"
          rules={[
            { required: true, message: '请输入版本' },
            { pattern: /^([0-9a-zA-Z_]|\.)+$/g, message: '只能包含英文、数字、下划线，英文点号(.)' },
            { min: 2, max: 10, message: '2-10字之间' },
          ]}
        >
          <Input placeholder="请输入版本" />
        </Form.Item>

        <Form.Item name="framework" label="框架类型" rules={[{ required: true, message: '请选择框架类型' }]}>
          <Select
            placeholder="请选择框架类型"
            options={[
              { label: 'vue', value: 1 },
              { label: 'react', value: 2 },
            ]}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={generatingScaffold} loading={generatingScaffold}>
            {generatingScaffold ? '生成中...' : '生成并下载'}
          </Button>
        </Form.Item>
      </Form>
      {contextHolder}
    </>
  );
}
