import React from 'react';
import { Breadcrumb, theme } from 'antd';
import type { BreadcrumbItemType, ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';

type ConsoleContentWrapperProps = {
  wrapperStyle?: React.CSSProperties;
  wrapperClassName?: string;
  breadCrumbItems?: ItemType[];
  filter?: React.ReactNode;
  children?: React.ReactNode;
  contentStyle?: React.CSSProperties;
  contentClassName?: string;
};

// 后台列表页面包装, 只需传入路由, filter 内容和页面内容
export default function ConsoleContentWrapper({
  wrapperStyle = {},
  wrapperClassName = '',
  breadCrumbItems = [],
  filter,
  children,
  contentStyle = {},
  contentClassName = '',
}: ConsoleContentWrapperProps) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div style={{ padding: 24, ...wrapperStyle }} className={wrapperClassName}>
      {/* 面包屑 */}
      <Breadcrumb
        // prettier-ignore
        itemRender={(item: ItemType, params: any, items: ItemType[], paths: string[]) => {// NOSONAR
          const last = items.indexOf(item) === items.length - 1;
          return last || !(item as BreadcrumbItemType).path ? (
            <span>{(item as BreadcrumbItemType).title}</span>
          ) : (
            <Link to={(item as BreadcrumbItemType)?.path!}>{(item as BreadcrumbItemType).title}</Link>
          );
        }}
        items={breadCrumbItems}
      />
      {/* 搜索框 */}
      {filter && <div style={{ marginTop: '16px', padding: '12px 24px', background: colorBgContainer }}>{filter}</div>}
      {/* 内容 */}
      <div
        style={{ marginTop: 16, padding: '24px', background: colorBgContainer, minHeight: '70vh', ...contentStyle }}
        className={contentClassName}
      >
        <>{children}</>
      </div>
    </div>
  );
}
