import React, { useEffect, useState } from 'react';
import { Space, theme, Typography, Image, Button, message, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Application, getApplicationDetail } from '../../api/application';
import ShowInput from '../../components/ShowInput';
import { CopyOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import ConsoleContentWrapper from '../../components/ConsoleContentWrapper';
import { useImage } from '../../hooks/useImage';

export default function ApplicationDetail() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();
  const [detail, setDetail] = useState<Application>();
  const [showAppSecret, setShowAppSecret] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
  const { imageUrl: logo, loadingImage } = useImage(detail?.logoResource);

  // 获取应用详情
  useEffect(() => {
    if (id) {
      setLoadingDetail(true);
      getApplicationDetail(id)
        .then(res => {
          setDetail(res.data);
        })
        .finally(() => {
          setLoadingDetail(false);
        });
    }
  }, [id]);

  return (
    <ConsoleContentWrapper
      contentStyle={{ marginTop: '16px', padding: '48px 64px', background: colorBgContainer, minHeight: '80vh' }}
      breadCrumbItems={[
        {
          title: '应用',
        },
        {
          title: '应用管理',
          path: '/console/application/management',
        },
        {
          title: '详情',
        },
      ]}
    >
      <Spin spinning={loadingDetail}>
        <Space direction="vertical" size={32}>
          <Space size="middle">
            <Typography.Text>应用名称:</Typography.Text>
            <ShowInput value={detail?.name} />
          </Space>
          <Space size="middle">
            <Typography.Text>授权接口数:</Typography.Text>
            <ShowInput value={detail?.apiAuthorities?.length ?? 0} />
            <Typography.Link
              onClick={() => {
                navigate(`/console/application/management/api-authority/${detail?.id}`);
              }}
            >
              查看
            </Typography.Link>
          </Space>
          <Space size="middle">
            <Typography.Text>状态:</Typography.Text>
            <ShowInput value={detail?.status === 1 ? '正常' : '禁用'} />
          </Space>
          <Space size="middle">
            <Typography.Text>appID:</Typography.Text>
            <ShowInput value={detail?.appKey} />
          </Space>
          <Space size="middle">
            <Typography.Text>Appsercret:</Typography.Text>
            <ShowInput value={showAppSecret ? detail?.appSecret : '*'.repeat(detail?.appSecret?.length ?? 2)} />
            <div
              style={{ cursor: 'pointer', color: colorPrimary }}
              onClick={() => {
                setShowAppSecret(!showAppSecret);
              }}
            >
              {showAppSecret ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </div>
            <CopyOutlined
              onClick={() => {
                messageApi.open({
                  type: 'success',
                  content: '已成功复制',
                });
                navigator.clipboard.writeText(detail?.appSecret ?? '');
              }}
              style={{ cursor: 'pointer', color: colorPrimary }}
            />
          </Space>
          <Space size="middle">
            <Typography.Text>应用图标:</Typography.Text>
            <Spin spinning={loadingImage}>
              <Image width={120} height={60} src={detail?.logoResource ? logo : '/app-default.png'} />
            </Spin>
          </Space>
          <Space size="middle">
            <Typography.Text>备注:</Typography.Text>
            <ShowInput value={detail?.memo} />
          </Space>
        </Space>
      </Spin>

      <Space style={{ marginTop: '20%' }}>
        <Button
          type="default"
          onClick={() => {
            navigate('/console/application/management');
          }}
        >
          返回列表
        </Button>
      </Space>

      {contextHolder}
    </ConsoleContentWrapper>
  );
}
