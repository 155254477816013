import React from 'react';
import { List, Typography, Image, Row, Col, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import { Application } from '../../api/application';
import { useNavigate } from 'react-router-dom';
import { useImage } from '../../hooks/useImage';

type ApplicationListItemProps = {
  // 是否被禁用
  disabled?: boolean;
  // 应用信息
  item: Application;
};

export default function ApplicationListItem({ disabled = false, item }: ApplicationListItemProps) {
  const navigate = useNavigate();
  const { imageUrl: logo, loadingImage } = useImage(item?.logoResource);

  return (
    <List.Item
      actions={[
        <Typography.Link
          disabled={disabled}
          key={item.id}
          onClick={() => {
            navigate(`/console/application/management/detail/${item.id}`);
          }}
        >
          查看
        </Typography.Link>,
        <Typography.Link
          key={item.id}
          disabled={disabled}
          onClick={() => {
            navigate(`/console/application/management/api-authority/${item.id}`);
          }}
        >
          权限
        </Typography.Link>,
        // <Typography.Link disabled={disabled}>配置</Typography.Link>,
      ]}
    >
      <List.Item.Meta
        style={{ alignItems: 'center' }}
        avatar={
          <Spin spinning={loadingImage}>
            <Image width={120} height={60} src={item.logoResource ? logo : '/app-default.png'} />
          </Spin>
        }
        title={<Typography.Title level={4}>{item.name}</Typography.Title>}
        description={
          <Row gutter={[24, 16]}>
            <Col span={12}>
              <Space size="middle">
                <Typography.Text type="secondary">创建时间:</Typography.Text>
                <Typography.Text type="secondary">
                  {dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                </Typography.Text>
              </Space>
            </Col>
            <Col span={12}>
              <Space size="middle">
                <Typography.Text type="secondary">应用ID:</Typography.Text>
                <Typography.Text type="secondary">{item.appKey}</Typography.Text>
              </Space>
            </Col>
            <Col span={12}>
              <Space size="middle">
                <Typography.Text type="secondary">更新时间:</Typography.Text>
                <Typography.Text type="secondary">
                  {dayjs(item.updateTime).format('YYYY-MM-DD HH:mm:ss')}
                </Typography.Text>
              </Space>
            </Col>
            <Col span={12}>
              <Space>
                <Typography.Text type="secondary">接口权限:</Typography.Text>
                <Typography.Text type="secondary">{item?.apiAuthorities?.length || 0}</Typography.Text>
              </Space>
            </Col>
          </Row>
        }
      />
    </List.Item>
  );
}
