import axios from '../axios';
import { V2Response } from './utils';

export interface GenerateScaffoldParams {
  // 应用名称
  appName: string;
  // 版本
  version: string;
  // 包名
  packageName: string;
  groupId: string;
  artifactId: string;
}

export interface GenerateFrontScaffoldParams {
  // 应用名称
  appName: string;
  // 版本
  version: string;
  // 框架类型
  framework: string;
}

// 生成后端脚手架
export function generateScaffold(params: GenerateScaffoldParams) {
  return axios.post<V2Response<{ value: string }>>('/api/v2/scaffold/generate', {
    ...params,
    timestamp: new Date().valueOf(),
  });
}

// 生成前端脚手架
export function generateFrontScaffold(params: GenerateFrontScaffoldParams) {
  return axios.post<V2Response<{ value: string }>>('/api/v2/scaffold/generate/front', {
    ...params,
    timestamp: new Date().valueOf(),
  });
}

// 下载小程序框架
export function downloadMiniApp() {
  return axios.post<V2Response<{ value: string }>>('/api/v2/scaffold/downloadMiniApp', {
    timestamp: new Date().valueOf(),
  });
}
