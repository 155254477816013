import { useState } from 'react';
import { Form, Input, Button, Row, Col, Alert, Modal } from 'antd';
import { generateScaffold, GenerateScaffoldParams } from '../../api/scaffold';
import { getRealUrl } from '@maxtropy/components';

export default function BackEndScaffold() {
  const [modal, contextHolder] = Modal.useModal();
  const [generatingScaffold, setGeneratingScaffold] = useState<boolean>(false);

  return (
    <>
      <Row style={{ marginBottom: 24 }}>
        <Col offset={4} span={16}>
          <Alert message="当前仅仅支持maven 项目生成，其他项目骨架，请参考开发指南。" type="warning" showIcon />
        </Col>
      </Row>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        labelAlign="right"
        onFinish={(values: GenerateScaffoldParams) => {
          setGeneratingScaffold(true);
          generateScaffold(values)
            .then(res => {
              modal.success({
                content: '生成脚手架成功',
              });
              window.open(getRealUrl(res.data.data.value), '__blank');
            })
            .finally(() => {
              setGeneratingScaffold(false);
            });
        }}
      >
        <Form.Item
          name="appName"
          label="应用名称"
          required
          rules={[
            { required: true, message: '请输入应用名称' },
            { pattern: /^[a-zA-Z0-9-_]*$/, message: '只能包含英文、数字、下划线和横线' },
            { min: 5, max: 30, message: '5-30字之间' },
          ]}
        >
          <Input placeholder="请输入应用名称" />
        </Form.Item>

        <Form.Item
          name="version"
          label="版本"
          required
          rules={[
            { required: true, message: '请输入版本' },
            { pattern: /^([0-9a-zA-Z-_]|\.)+$/g, message: '只能包含英文、数字、下划线，英文点号(.)和横线' },
            { min: 2, max: 10, message: '2-10字之间' },
          ]}
        >
          <Input placeholder="请输入版本" />
        </Form.Item>

        <Form.Item
          name="packageName"
          label="包名"
          required
          rules={[
            { required: true, message: '请输入包名' },
            { pattern: /^([0-9a-zA-Z-_]|\.)+$/g, message: '只能包含英文、数字、下划线，英文点号(.)和横线' },
            { min: 5, max: 30, message: '5-30字之间' },
          ]}
        >
          <Input placeholder="请输入包名" />
        </Form.Item>
        <Form.Item
          name="groupId"
          label="GroupId"
          required
          rules={[
            { required: true, message: '请输入GroupId' },
            { pattern: /^([0-9a-zA-Z-_]|\.)+$/g, message: '只能包含英文、数字、下划线，英文点号(.)和横线' },
            { min: 5, max: 30, message: '5-30字之间' },
          ]}
        >
          <Input placeholder="请输入GroupId" />
        </Form.Item>
        <Form.Item
          name="artifactId"
          label="ArtifactId"
          required
          rules={[
            { required: true, message: '请输入ArtifactId' },
            { pattern: /^([0-9a-zA-Z-_]|\.)+$/g, message: '只能包含英文、数字、下划线，英文点号(.)和横线' },
            { min: 5, max: 30, message: '5-30字之间' },
          ]}
        >
          <Input placeholder="请输入ArtifactId" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={generatingScaffold} loading={generatingScaffold}>
            {generatingScaffold ? '生成中...' : '生成并下载'}
          </Button>
        </Form.Item>
      </Form>

      {contextHolder}
    </>
  );
}
