import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import axios from '../axios';

export interface ApplicationListQuery extends PageRequest {
  // 应用名称
  name?: string;
  // 服务商 id
  independentSoftwareVendorId?: string;
  // 应用状态, 0 :禁用, 1 :启用
  status?: 0 | 1 | '0' | '1';
}

export interface APIAuthority {
  id: number;
  // 服务名称
  serviceName: string;
  // 请求地址
  requestUrl: string;
  // 请求方法
  requestMethod: string;
}

export interface Application {
  id: number;
  // 应用名字
  name?: string;
  // 服务商 id
  independentSoftwareVendorId?: number;
  // 服务商名称
  independentSoftwareVendorName?: string;
  // 备忘录
  memo?: string;
  // 应用 key
  appKey?: string;
  // 应用秘钥
  appSecret?: string;
  // 状态, 0 禁用, 1 启用
  status?: 0 | 1;
  // 创建人
  createByUsername?: string;
  // 租户可见范围
  tenantAuthorityType?: number;
  // logo 图片
  logoResource?: string;
  // api 权限接口
  apiAuthorities?: APIAuthority[];
  tenantAuthorities?: {
    id: number;
    tenantName: string;
    tenantMcid: string;
  }[];
  createTime?: string;
  updateTime?: string;
}

// 获取应用列表
export function getApplicationList(query: ApplicationListQuery) {
  return axios.get<PageResponse<Application>>(`/api/application/page?${qs.stringify(query, { indices: false })}`);
}

// 获取应用详情
export function getApplicationDetail(applicationId: string | number) {
  return axios.get<Application>(`/api/application/${applicationId}`);
}
